<template>
  <v-container fluid>
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <i-toolbar
            :title="
              expense.pk
                ? `${$t('edit')} ${$tc(title, 1)}`
                : `${$t('add', { value: $tc(title, 1) })}`
            "
            :loading="load"
          ></i-toolbar>
          <v-card-text>
            <v-row class="py-5">
              <!-- Date Picker -->
              <v-col cols="12" lg="3" md="3" xs="12" pr-4>
                <i-date-picker
                  v-model="expense.date"
                  :label="$t('date')"
                  color="primary"
                  :max="
                    !recurrent ? new Date().toISOString().substr(0, 10) : ''
                  "
                  :min="recurrent ? new Date().toISOString().substr(0, 10) : ''"
                />
              </v-col>

              <!-- Account Selection -->
              <v-col cols="12" lg="3" md="3" sm="6">
                <dialAccount
                  v-model="dialogAccount"
                  :to-edit="toEditAccount"
                  @done="addAccount"
                />
                <ValidationProvider
                  vid="account"
                  :name="$tc('account', 1)"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    autocomplete="off"
                    outlined
                    :clearable="accounts.length > 10"
                    v-model="expense.account_id"
                    :items="accounts"
                    item-text="name"
                    item-value="pk"
                    :label="$tc('account', 2)"
                    :error-messages="errors[0]"
                    :append-icon="accounts.length > 10 ? 'fa-search' : ''"
                    :prepend-inner-icon="
                      getPermissions([`expenses.add_account`]) || isAdmin
                        ? 'fa-plus'
                        : ''
                    "
                    @click:prepend-inner="openAccountModal()"
                  />
                </ValidationProvider>
              </v-col>

              <!-- Category Selection -->
              <v-col cols="12" lg="3" md="3" sm="6">
                <dialCategory
                  v-model="dialogCategory"
                  :to-edit="toEditCategory"
                  @done="addCategory"
                />
                <ValidationProvider
                  vid="category"
                  :name="$tc('category', 1)"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    autocomplete="off"
                    outlined
                    :clearable="categories.length > 10"
                    v-model="expense.category_id"
                    :items="categories"
                    item-text="name"
                    item-value="pk"
                    :label="$tc('category', 2)"
                    :error-messages="errors[0]"
                    :append-icon="categories.length > 10 ? 'fa-search' : ''"
                    :prepend-inner-icon="
                      getPermissions([`expenses.add_category`]) || isAdmin
                        ? 'fa-plus'
                        : ''
                    "
                    @click:prepend-inner="openCategoryModal()"
                  />
                </ValidationProvider>
              </v-col>

              <!-- Operator Field -->
              <v-col cols="12" lg="3" md="3" sm="6">
                <ValidationProvider
                  vid="operator"
                  :name="$t('operator')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="expense.operator"
                    :error-messages="errors[0]"
                    :label="$t('operator')"
                  />
                </ValidationProvider>
              </v-col>

              <!-- Description Field -->
              <v-col cols="12" lg="3" md="3" sm="6">
                <ValidationProvider
                  vid="description"
                  :name="$tc('description', 1)"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="expense.description"
                    :error-messages="errors[0]"
                    :label="$tc('description', 1)"
                    color="secondary"
                  />
                </ValidationProvider>
              </v-col>

              <!-- Reference Field -->
              <v-col cols="12" lg="3" md="3" sm="6">
                <ValidationProvider
                  vid="reference"
                  :name="$tc('reference', 1)"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="expense.reference"
                    :error-messages="errors[0]"
                    :label="$tc('reference', 1)"
                    color="secondary"
                  />
                </ValidationProvider>
              </v-col>

              <!-- Recurrent Type Selection -->
              <v-col v-if="recurrent" cols="12" lg="3" md="3" sm="6">
                <ValidationProvider
                  vid="type_recurrent"
                  :name="$tc('type_recurrent', 1)"
                  :rules="{ required: recurrent }"
                  v-slot="{ errors }"
                >
                  <v-select
                    autocomplete="off"
                    outlined
                    v-model="expense.type_recurrent"
                    :error-messages="errors[0]"
                    item-text="state"
                    item-value="abbr"
                    :items="[
                      { state: $t('yearly'), abbr: 'year' },
                      { state: $t('monthly'), abbr: 'month' },
                      { state: $t('weekly'), abbr: 'week' }
                    ]"
                    :label="$t('type_recurrent')"
                  />
                </ValidationProvider>
              </v-col>

              <!-- Day Selection for Weekly Recurrent -->
              <v-col
                v-if="recurrent && expense.type_recurrent === 'week'"
                cols="12"
                lg="3"
                md="3"
                sm="6"
              >
                <ValidationProvider
                  vid="days"
                  :name="$tc('days', 1)"
                  :rules="{ required: expense.type_recurrent === 'week' }"
                  v-slot="{ errors }"
                >
                  <v-select
                    autocomplete="off"
                    outlined
                    v-model="expense.day"
                    :error-messages="errors[0]"
                    item-text="value"
                    item-value="key"
                    :items="days"
                    :label="$t('days')"
                  />
                </ValidationProvider>
              </v-col>

              <!-- Payment Type Selection -->
              <v-col
                v-if="
                  (expense.pk !== '' && expense.pk !== undefined) || !recurrent
                "
                cols="12"
                lg="3"
                md="3"
                sm="6"
              >
                <ValidationProvider
                  vid="pay"
                  :name="$t('paymenttype')"
                  rules=""
                  v-slot="{ errors }"
                >
                  <v-select
                    autocomplete="off"
                    outlined
                    v-model="expense.pay"
                    :error-messages="errors[0]"
                    item-text="state"
                    item-value="abbr"
                    :items="[
                      { state: $t('cc'), abbr: 'cc' },
                      { state: $t('cash'), abbr: 'cash' },
                      { state: $t('check'), abbr: 'check' }
                    ]"
                    :label="$t('paymenttype')"
                  />
                </ValidationProvider>
              </v-col>

              <!-- Clone Button -->
              <v-col
                v-if="
                  recurrent && expense.pk !== '' && expense.pk !== undefined
                "
                cols="1"
              >
                <i-btn
                  :title="$tc('clone', 1)"
                  outlined
                  classes="mx-1"
                  icon="fa-clone"
                  color="secondary"
                  @click="expense.payment = expense.cost"
                />
              </v-col>

              <!-- Cost Field -->
              <v-col
                cols="12"
                :lg="
                  recurrent && expense.pk !== '' && expense.pk !== undefined
                    ? 2
                    : 3
                "
              >
                <ValidationProvider
                  vid="cost"
                  :name="recurrent ? $t('payment_ref') : $tc('cost', 1)"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    :disabled="
                      recurrent && expense.pk !== '' && expense.pk !== undefined
                    "
                    v-model="expense.cost"
                    :error-messages="errors[0]"
                    type="number"
                    step="0.00"
                    color="secondary"
                    :label="recurrent ? $t('payment_ref') : $tc('cost', 1)"
                  />
                </ValidationProvider>
              </v-col>

              <!-- Payment Field for Recurrent Expenses -->
              <v-col
                v-if="
                  expense.pk !== '' && expense.pk !== undefined && recurrent
                "
                cols="12"
                lg="3"
                md="3"
                sm="6"
              >
                <ValidationProvider
                  vid="payment"
                  :name="$tc('payment', 1)"
                  rules=""
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="expense.payment"
                    :error-messages="errors[0]"
                    type="number"
                    step="0.00"
                    color="secondary"
                    :label="$tc('payment', 1)"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
        </form>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import dialCategory from '../../components/expenseCategory/modal.vue'
import dialAccount from '../../components/expenseAccount/modal.vue'

export default {
  components: {
    dialCategory,
    dialAccount
  },
  props: {
    recurrent: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    }
  },
  data() {
    return {
      date: '',
      expense: {
        category_id: 0,
        account_id: 0,
        date: '',
        operator: '',
        description: '',
        cost: 0.0,
        payment: 0.0,
        pay: 'cash',
        reference: '',
        recurrent: this.recurrent,
        type_recurrent: '',
        day: ''
      },
      load: false,
      loading: false,
      categories: [],
      accounts: [],
      days: [
        { key: 'mon', value: this.$t('days_list.mon') },
        { key: 'tue', value: this.$t('days_list.tue') },
        { key: 'wed', value: this.$t('days_list.wed') },
        { key: 'thu', value: this.$t('days_list.thu') },
        { key: 'fri', value: this.$t('days_list.fri') },
        { key: 'sat', value: this.$t('days_list.sat') },
        { key: 'sun', value: this.$t('days_list.sun') }
      ],
      dialogCategory: false,
      dialogAccount: false,
      toEditCategory: null,
      toEditAccount: null
    }
  },
  methods: {
    /**
     * submit
     * Método para enviar el formulario de gasto, ya sea creando un nuevo gasto
     * o editando uno existente. Muestra un mensaje de éxito al finalizar la
     * operación.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async submit() {
      try {
        this.load = true
        this.expense.pk
          ? await this.$api.expense.edit({
              pk: this.expense.pk,
              form: this.expense
            })
          : await this.$api.expense.create({
              form: this.expense
            })
        this.$toast.success(
          `${this.$tc('expense', 1)} ${this.$tc(
            this.expense.pk ? 'edited' : 'created',
            2
          )}`
        )
        if (!this.expense.pk) {
          const audio = new Audio(require('@/../public/sounds/sound.mp3'))
          audio.play()
        }
        this.$router.push({
          name: this.recurrent ? 'expensesRecurring' : 'expensesRegular'
        })
      } finally {
        this.load = false
        this.getAccounts()
        this.getCategories()
      }
      if (this.recurrent) this.notification()
    },

    /**
     * notification
     * Método asíncrono que verifica si hay notificaciones de mantenimiento
     * relacionadas con el área de equipos.
     * Primero, se obtiene el tipo de contenido asociado al modelo de mantenimiento.
     * Luego, se consulta la lista de notificaciones para determinar si hay
     * alguna que requiera el envío de un correo electrónico.
     *
     * Si la notificación existe y tiene habilitada la opción de correo electrónico,
     * se llama al método sendMail() para enviar la notificación correspondiente.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async notification() {
      try {
        const { data: areas } = await this.$api.role.areas.list({
          opt: {
            params: {
              app_label: 'expenses',
              model: 'expense'
            }
          }
        })
        if (!areas.length) return
        const { data: notifications } = await this.$api.notifications.list({
          opt: {
            params: {
              content_type: areas[0].pk
            }
          }
        })
        if (notifications.length > 0) {
          const notification = notifications[0]

          if (notification.email) {
            this.sendMail()
          }
          if (notification.push) {
            this.$store.dispatch('notifications/addNotification', this.expense)
          }
        }
      } catch (error) {
        console.error('Error en la notificación:', error)
      }
    },
    /**
     * sendMail
     * Método asíncrono que envía un correo electrónico con los datos de
     * mantenimiento del equipo.
     * Se construye un objeto de correo electrónico que incluye el usuario,
     * el ID de la empresa,
     * el asunto, el cuerpo del mensaje y la lista de destinatarios.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async sendMail() {
      this.loading = true
      let email = {
        user: this.me['id'],
        company_id: this.company.pk,
        subject: 'Expense: ' + this.expense.reference,
        body:
          'Operator: ' +
          this.expense.operator +
          '  ' +
          'Description: ' +
          this.expense.description,
        recipient_list: this.me['email']
      }
      try {
        await this.$api.email.send({
          opt: {
            params: {
              template: false
            }
          },
          form: email
        })
        this.$toast.success(`${this.$tc('email', 1)} ${this.$tc('send', 2)}`)
      } finally {
        this.loading = false
      }
    },

    /**
     * getExpenses
     * Método para obtener los detalles de un gasto. Inicializa un nuevo gasto
     * si se está creando o carga los detalles de un gasto existente si se está
     * editando.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    getExpenses() {
      this.loading = true
      let validation = this.codification({
        mode: 'decode',
        code: this.$route.params.pk
      })
      if (validation == 'create') {
        if (this.$store.getters['utils/getIsloading']) {
          this.$store.dispatch('utils/actionLoading')
          this.$route.meta.title = this.$t('create', { model: '' })
        }
        this.expense = {
          category_id: 0,
          account_id: 0,
          date: '',
          operator: '',
          description: '',
          cost: 0.0,
          payment: 0.0,
          pay: 'cash',
          reference: '',
          recurrent: this.recurrent,
          type_recurrent: '',
          day: ''
        }
      } else {
        this.$api.expense
          .show({ pk: validation })
          .then((res) => {
            this.expense = res.data
            this.$route.meta.title = this.$t('edit', { model: '' })
          })
          .catch(() => this.$router.post({ name: 'expensesRegular' }))
      }
    },
    /**
     * getAccounts
     * Método para obtener la lista de cuentas desde la API y actualizar el
     * estado de cuentas en el componente.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getAccounts(evt) {
      const account = await this.$api.expense.account.list({})
      this.accounts = account.data.results
    },
    /**
     * addAccount
     * Método para agregar nueva cuenta a la lista, si se proporciona un evento.
     *
     * @param {Object} evt - Evento que puede contener una nueva cuenta
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    addAccount(evt) {
      this.accounts.push(evt)
      this.expense.account_id = evt.pk
    },
    /**
     * getCategories
     * Método para obtener la lista de categorías desde la API y actualizar el
     * estado de categorías en el componente
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getCategories(evt) {
      const category = await this.$api.expense.category.list({})
      this.categories = category.data.results
    },
    /**
     * addCategory
     * Método para agregar nueva categoría a la lista, si se proporciona un
     * evento.
     *
     * @param {Object} evt - Evento que puede contener una nueva categoría
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    addCategory(evt) {
      this.categories.push(evt)
      this.expense.category_id = evt.pk
    },
    /**
     * openCategoryModal
     * Método para abrir el modal de categoría. Asigna el ítem a editar o null
     * si no hay.
     *
     * @param {Object|null} item - Ítem de categoría a editar o null
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>

     */
    openCategoryModal(item) {
      this.toEditCategory = item || null
      this.dialogCategory = true
    },
    /**
     * openAccountModal
     * Método para abrir el modal de cuenta. Asigna el ítem a editar o null si
     * no hay.
     *
     * @param {Object|null} item - Ítem de cuenta a editar o null
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    openAccountModal(item) {
      this.toEditAccount = item || null
      this.dialogAccount = true
    }
  },
  mounted() {
    this.getCategories()
    this.getAccounts()
    this.getExpenses()
  },
  watch: {
    'expense.date': {
      handler(val) {
        if (val) {
          this.date = this.dateFormat(val)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/getCompanyData',
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin',
      me: 'session/me'
    })
  }
}
</script>
